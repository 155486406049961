<template>
  <div class="page">
    <img class="bg" src="@/assets/images/farming.png" alt="" />
    <div class="g-container">
      <div class="body">
        <div class="main">
          <div class="header">
            <div class="title">
              <div class="title-item current">讲话详情</div>
              <div
                class="title-item"
                @click="$router.push({ path: '/farming/detail/index', query: { menuId: menuId } })"
                >重要论述</div
              >
              <div class="title-item" @click="$router.push('/farming/list/index')">目录</div>
            </div>
            <el-button style="margin-left: auto; background-color: #fa541c;" type="danger" @click="onCreate"
              >工作情况落实</el-button
            >
          </div>
          <div class="info g-bg g-p-15">
            <div class="content">{{ info.content }}</div>
            <div class="source">{{ info.source }}</div>
          </div>

          <div class="g-bg g-mt-15">
            <div class="tab">
              <div class="tab-item" :class="{ active: currentTab === 'all' }" @click="onTabChange('all')">全 部</div>
              <div class="tab-item" :class="{ active: currentTab === 'self' }" @click="onTabChange('self')"
                >只看我发布的</div
              >
            </div>
            <div v-if="list.length === 0" class="g-empty"></div>
            <div class="g-p-20" v-loading="loading">
              <div class="item" v-for="item in list" :key="item.id">
                <div class="item-header">
                  <div class="user">
                    <span class="dept">{{ item.department.name }}</span>
                    <span class="g-ml-10">{{ item.user.name }}</span>
                    <span class="g-ml-5">发表于</span>
                    <span>{{ item.created_at }}</span>
                  </div>
                  <div class="action" v-if="item.user_id === userId">
                    <el-button type="text" icon="el-icon-delete" @click="onDelete(item)"></el-button>
                    <el-button type="text" icon="el-icon-edit" @click="onUpdate(item)"></el-button>
                  </div>
                </div>
                <div class="content">{{ item.content }}</div>
                <div class="images" v-if="item.images && item.images.length > 0">
                  <c-image-list :list="item.images"></c-image-list>
                </div>
                <div class="media" v-if="item.media && item.media.length > 0">
                  <c-media-list :list="item.media"></c-media-list>
                </div>
                <div class="footer">
                  <div class="g-ml-auto">
                    <el-button class="btn-praise" @click="onFabulous(item)" type="text">
                      <template v-if="item.fabulous_status">
                        <img class="icon-praise" src="@/assets/icons/praise-red.png" alt="" />
                        <span>已赞</span>
                      </template>
                      <template v-else>
                        <img class="icon-praise" src="@/assets/icons/praise-grey.png" alt="" />
                        <span>点赞</span>
                      </template>
                    </el-button>
                    <el-button type="text" @click="onComment(item)" icon="el-icon-chat-dot-round">回复</el-button>
                  </div>
                </div>
              </div>
              <b-pagination :page="page" @change="fetchPage"></b-pagination>
            </div>
          </div>
        </div>
        <div class="aside">
          <div style="height: 52px;"></div>
          <div class="g-bg g-px-10">
            <div class="aside-title">落实最多的讲话</div>
            <div class="g-empty"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="g-pb-30"> </div>
    <form-view ref="actionForm" @success="onRefreshPage"></form-view>
    <comment-view ref="comment" @success="onRefreshPage"></comment-view>
  </div>
</template>

<script>
import { getActionPage, getDetail, deleteAction, updateFavorite } from '@/api/farming'
import CImageList from '@/components/upload/image-list'
import CMediaList from '@/components/upload/media-list'
import FormView from './form.vue'
import CommentView from './comment.vue'

export default {
  components: {
    FormView,
    CommentView,
    CImageList,
    CMediaList,
  },
  data() {
    return {
      menuId: null,
      detailId: null,
      loading: false,
      info: {},
      list: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      currentTab: 'all',
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        this.detailId = id
        this.menuId = this.$route.query.menuId
        this.fetchPage()
        this.fetchInfo()
      },
      immediate: true,
    },
  },
  methods: {
    fetchInfo() {
      getDetail(this.detailId).then(res => {
        this.info = res
      })
    },
    fetchPage() {
      this.loading = true
      const params = {
        menu_id: this.detailId,
        page: this.page.current,
        examine_status: 'pass',
      }
      if (this.currentTab === 'self') {
        params.user_id = this.userId
      }
      getActionPage(params)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefreshPage() {
      this.page.current = 1
      this.fetchPage()
    },
    onCreate() {
      const params = {
        content: '',
        images: [],
        media_keys: [],
      }
      this.$refs.actionForm.open(params, this.detailId)
    },
    onUpdate(record) {
      const params = {
        id: record.id,
        content: record.content,
        images: record.images,
        media_keys: record.media,
      }
      this.$refs.actionForm.open(params)
    },
    onDelete(record) {
      this.$confirm('确定删除当前项吗？', '提示', { type: 'warning' })
        .then(() => {
          return deleteAction(record.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefreshPage()
        })
        .catch(() => {})
    },
    onNav(record) {
      this.$router.push({})
    },
    onTabChange(tab) {
      if (this.currentTab === tab) return
      this.currentTab = tab
      this.onRefreshPage()
    },
    onComment(record) {
      this.$refs.comment.open(record.id)
    },
    onFabulous(record) {
      updateFavorite(record.id).then(res => {
        if (record.fabulous_status) {
          this.$message.success('已取消点赞')
        } else {
          this.$message.success('已点赞')
        }
        this.onRefreshPage()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  overflow: hidden auto;
  background-color: #ffe2d7;
}

.bg {
  width: 100%;
  height: auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .title {
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff;
    border-radius: 0 19px 19px 0;

    .title-item {
      width: 100px;
      line-height: 28px;
      color: #fff;
      text-align: center;
      background-color: #fa541c;
      border-radius: 0 19px 19px 0;
      box-shadow: 0 2px 3px 0 rgba(131, 25, 9, 0.27);

      &:not(:last-child) {
        width: 120px;
        margin-left: -20px;
      }

      &:hover:not(.current) {
        cursor: pointer;
        background-color: #e64b17;
      }

      &.current {
        color: #fa541c;
        background-color: #fff;
      }
    }
  }
}

.aside-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  color: #1f1f1f;
}

.body {
  display: flex;

  .main {
    flex: 7;
  }

  .aside {
    flex: 2;
    margin-left: 15px;
  }
}

.info {
  .content {
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }

  .source {
    margin-top: 15px;
    font-size: 12px;
    line-height: 20px;
    color: #858c96;
  }
}

.tab {
  display: flex;

  .tab-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    line-height: 38px;
    color: #858c96;
    background: #f8f6f6;
    border-radius: 6px 6px 0 0;

    &:hover {
      color: #fa541c;
      cursor: pointer;
    }

    &.active {
      color: #fa541c;

      &::after {
        position: relative;
        z-index: 1;
        display: block;
        width: 30px;
        height: 2px;
        content: '';
        background-color: #fa541c;
      }
    }
  }
}

.item {
  padding: 5px;
  font-size: 14px;
  line-height: 30px;
  color: #1f1f1f;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 10px;

  .item-header {
    display: flex;
    justify-content: space-between;

    .user {
      font-size: 12px;
      color: #858c96;

      .dept {
        font-size: 14px;
        color: #1f1f1f;
      }
    }
  }

  .content {
    margin-top: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-size: 12px;
    color: #9a9da1;

    .active {
      color: #fa541c;
    }

    // .btn-praise {
    //   display: flex;
    //   align-items: center;
    // }

    .icon-praise {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      vertical-align: middle;
    }

    .link:hover {
      color: #858c96;
      cursor: pointer;
    }
  }
}
</style>
